import { Injectable, OnDestroy, inject } from '@angular/core';
import {
  CreateFolderGQL,
  CreateFolderInput,
  CreateFolderMutation,
  DeleteFolderListGQL,
  Folder,
  FolderType,
  GetProfileFoldersGQL,
  GetProfileFoldersQuery,
  GetProfileFoldersQueryVariables,
  GetProfileFoldersWithMediasGQL,
  GetProfileFoldersWithMediasQuery,
  GetProfileFoldersWithMediasQueryVariables,
  Maybe,
  Media,
  MoveFolderListToFolderGQL,
  UpdateFolderGQL,
  UpdateFolderInput,
} from '@designage/gql';
import { CreateFolderDialogComponent } from '@desquare/components/common/src/modals/create-folder-dialog.component';
import { DeleteFolderDialogComponent } from '@desquare/components/common/src/modals/delete-folder-dialog.component';
import { MoveFolderToFolderDialogComponent } from '@desquare/components/common/src/modals/move-folder-to-folder-dialog.component';
import { UpdateFolderDialogComponent } from '@desquare/components/common/src/modals/update-folder-dialog.component';
import {
  IFolderNode,
  IToFolderNodeTreeOptions,
  IUpdateFolderForm,
} from '@desquare/interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  flatMapDeep,
  ListIterator,
  ListOfRecursiveArraysOrValues,
  orderBy,
} from 'lodash';
import { lastValueFrom, map, Subscription } from 'rxjs';
import { SubSink } from 'subsink';
import { CurrentUserService } from '../current-user/current-user.service';
import { ToasterService } from '../toaster/toaster.service';

export interface IServiceMutationOptions {
  showToaster?: boolean;
  refetchQueriesAfter?: boolean;
}

@Injectable({
  providedIn: 'root',
  // note: providedIn: null makes the service to be only instantiated if it
  // was injected into the component (added to the providers array).
  // If the component's children uses this service it will use the same
  // instance with the parent. More info: https://stackoverflow.com/a/72561645
})
export class FolderService implements OnDestroy {
  private subs = new SubSink();
  profileFoldersQuery?: QueryRef<
    GetProfileFoldersQuery,
    GetProfileFoldersQueryVariables
  >;
  profileFoldersWithMediasQuery?: QueryRef<
    GetProfileFoldersWithMediasQuery,
    GetProfileFoldersWithMediasQueryVariables
  >;

  createFolderSubscription?: Subscription;
  createFolderMutation?: CreateFolderMutation;
  TRASH_FOLDER_NAME = 'TRASH_BIN';

  constructor(
    private apollo: Apollo,
    private currentUserService: CurrentUserService,
    private getProfileFoldersGQL: GetProfileFoldersGQL,
    private getProfileFoldersWithMediasGQL: GetProfileFoldersWithMediasGQL,
    private createFolderGQL: CreateFolderGQL,
    private deleteFolderListGQL: DeleteFolderListGQL,
    private updateFolderGQL: UpdateFolderGQL,
    private moveFolderListToFolderGQL: MoveFolderListToFolderGQL,
    private toasterService: ToasterService,
    private modalService: NgbModal
  ) {}

  ngOnDestroy(): void {
    this.subs.unsubscribe();
    // console.log('FolderService subs unsubscribed'); // DEBUG
  }

  getProfileFoldersObs(profileId: string) {
    this.profileFoldersQuery = this.getProfileFoldersGQL.watch(
      { profileId },
      { fetchPolicy: 'no-cache' } // TODO: invariant error when using cache, solve later
    );

    return this.profileFoldersQuery.valueChanges;
  }

  async getProfileFolders(profileId: string) {
    return (
      (
        await lastValueFrom(
          this.getProfileFoldersGQL.fetch(
            { profileId },
            { fetchPolicy: 'no-cache' }
          )
        )
      ).data.profile?.folders || []
    );
  }

  getProfileFoldersWithMedias(profileId: string) {
    this.profileFoldersWithMediasQuery =
      this.getProfileFoldersWithMediasGQL.watch(
        { profileId },
        { fetchPolicy: 'no-cache' } // TODO: invariant error when using cache, solve later
      );

    return this.profileFoldersWithMediasQuery.valueChanges;
  }

  async getProfileFoldersWithFullPath(profileId: string) {
    const folders = await this.getProfileFolders(profileId);
    folders.forEach((f) => {
      f.fullPath = this.getFullPath(f, folders);
    });
    return folders;
  }

  getFullPath(f: Folder, folders: Folder[]): string {
    const parent = f.parentFolderId
      ? folders.find((x) => x.id === f.parentFolderId)
      : null;
    if (!parent) {
      return f.name;
    } else {
      return `${this.getFullPath(parent, folders)}/${f.name}`;
    }
  }

  /**
   * Gets the folder and its ancestor folders (parent folder, grandParent folder... until root folder)
   * and return folders into an ordered list (from root to current folder)
   *
   * @param folderId
   * @returns promise of list of folders
   */
  async getFullPathListById(
    folderId: string | null
  ): Promise<Folder[] | undefined> {
    const profileId = this.currentUserService.currentProfile?.id;
    if (!profileId) return;

    // if folderId is null
    // then the folder is the root folder
    if (!folderId) return;

    const folders = await this.getProfileFolders(profileId);
    const folder = folders.find(({ id }) => id === folderId);
    if (!folder) return;

    const getFullPathList = (folder: Folder, folders: Folder[]): Folder[] => {
      // find parent of folder
      const parent = folder.parentFolderId
        ? folders.find(({ id }) => id === folder.parentFolderId)
        : null;

      if (!parent) {
        // if no parent then return just the current folder in a list
        return [folder];
      } else {
        // if has parent then return list of current folder
        // with deconstructed list of parent folder (recursively)
        return [...getFullPathList(parent, folders), folder];
        // this results to a flat list with the current folder
        // and its ancestor folder(s)
      }
    };

    const fullPathList = getFullPathList(folder, folders);

    return fullPathList;
  }

  /**
   * Checks if folder is inside the Trash Folder
   *
   * @param folderId
   * @returns
   */
  async isFolderInTrash(folderId: string) {
    // get or create trash folder
    const trashFolder = await this.getTrashFolder();
    if (!trashFolder) return;

    // check ancestor folders if the current selected folder is in
    // the trash folder
    return (await this.getFullPathListById(folderId))?.find(
      ({ id }) => id === trashFolder.id
    )
      ? true
      : false;
  }

  createFolder(
    input: CreateFolderInput,
    options: IServiceMutationOptions = {
      showToaster: true,
      refetchQueriesAfter: true,
    }
  ) {
    const { showToaster, refetchQueriesAfter } = options;

    return this.createFolderGQL.mutate({ input }).pipe(
      map((result) => {
        const { data, loading, errors } = result;

        if (data?.createFolder?.isSuccessful) {
          if (showToaster) {
            this.toasterService.clear();
            this.toasterService.success('CREATE_FOLDER_SUCCESS');
          }

          if (refetchQueriesAfter) this.refetchQueries();
        }

        return result;
      })
    );
  }

  /**
   * Deletes folder(s) using the folder id field
   * - this returns a cold observable make sure to use
   * .subscribe() or lastValueFrom() to activate the mutation
   *
   * @param ids - pro tip: to pass a single id just wrap it with an array eg. deleteFolder([folder_id])
   * @returns
   */
  deleteFolders(ids: string[]) {
    return this.deleteFolderListGQL.mutate({ ids }).pipe(
      map((result) => {
        const { data, loading, errors } = result;

        if (data?.deleteFolderList.isSuccessful) {
          this.toasterService.clear();
          this.toasterService.success('DELETE_FOLDER_SUCCESS');
        }
        this.refetchQueries();

        return result;
      })
    );
  }

  updateFolder(input: UpdateFolderInput) {
    return this.updateFolderGQL.mutate({ input }).pipe(
      map((result) => {
        const { data, loading, errors } = result;

        if (data?.updateFolder?.isSuccessful) {
          this.toasterService.clear();
          this.toasterService.success('UPDATE_FOLDER_SUCCESS');
          this.refetchQueries();
        }

        return result;
      })
    );
  }

  moveFolderListToFolder(
    folderIds: string[],
    destinationFolderId: string | null
  ) {
    return this.moveFolderListToFolderGQL
      .mutate({
        folderIds,
        destinationFolderId,
      })
      .pipe(
        map((result) => {
          const { data, loading, errors } = result;

          if (data?.moveFolderListToFolder?.isSuccessful) {
            this.toasterService.clear();
            this.toasterService.success('MOVE_FOLDER_TO_FOLDER_SUCCESS');
            this.refetchQueries();
          }

          return result;
        })
      );
  }

  /**
   * The backend already handles in the create mutation that
   * if the trash folder doesn't exist then create trash folder
   * this ensures that only a single trash folder exists per profile
   *
   * @returns
   */
  async getTrashFolder() {
    const profileId = this.currentUserService.currentProfile?.id;
    if (!profileId) return;

    const trashFolder: Maybe<Folder> = await lastValueFrom(
      this.createFolder(
        {
          name: this.TRASH_FOLDER_NAME, // TODO: ask for a better display name for the trash folder
          profileId,
          folderType: FolderType.Trash,
        },
        { showToaster: false, refetchQueriesAfter: false }
      )
    ).then((result) => result.data?.createFolder?.folder);

    return trashFolder;
  }

  toFolderNode(folder: Folder, media?: Media[]): IFolderNode {
    const { id, name, folderType, medias } = folder;
    // console.log('totalMediaCount', totalMediaCount);

    return {
      id,
      name,
      folderType,
      children: [],
    };
  }

  /**
   * - Algorithm to generate the list of folderNodes (folder node tree)
   *   from a list of folder.
   * - The child folderNodes should be nested corresponding to their
   *   parent folder node.
   * @param folders
   * @param media
   * @returns
   */
  toFolderNodeTree(
    folders: Folder[],
    options?: IToFolderNodeTreeOptions,
    media?: Media[]
  ): IFolderNode[] {
    // optionally prefilter folders before turning into a tree, by default filter nothing
    const filteredFolders = folders.filter(
      options?.prefilter ?? ((folder) => folder)
    );

    // 1st step: create hash table of folder id (key) and the folder node object (value)
    const folderIdFolderNodeMap = new Map<string, IFolderNode>();
    // by default sort the folder list alphabetically
    orderBy(
      filteredFolders,
      [options?.sortOrder?.sortBy ?? 'name'],
      [options?.sortOrder?.orderBy ?? 'asc']
    ).forEach((folder) => {
      folderIdFolderNodeMap.set(folder.id, this.toFolderNode(folder, media));
    });

    // 2nd step: map children to their parents using the parentId field
    const folderNodeTree: IFolderNode[] = [];

    for (const [folderId, folderNode] of folderIdFolderNodeMap) {
      const folder = filteredFolders.find(({ id }) => id === folderId);
      if (!folder) continue;

      if (folder.parentFolderId) {
        // push folderNode into its parent
        folderIdFolderNodeMap
          .get(folder.parentFolderId)
          ?.children.push(folderNode);
        // note: pushing the folderNode into its parent
        // here will update the folderNodeTree as well since
        // by default in JS, objects and arrays are
        // pass by reference
      } else {
        folderNodeTree.push(folderNode);
      }
    }

    return folderNodeTree;
  }

  /**
   * This function filters the folder list, but when a folder is filtered
   * it also includes its descendants (folders with parentFolderId pointing to
   * the filtered folder) by transforming it into a tree. It will then be transformed
   * back into a folder list
   *
   * @param folders
   * @param targetIds
   * @returns folder list
   */
  deepFilterFolderList(
    folders: Folder[],
    filterFunction: (value: Folder, index: number, array: Folder[]) => unknown
  ): Folder[] {
    // transform into a tree structure, we do this so that its descendants gets filtered
    const folderTree = this.toFolderNodeTree(folders, {
      prefilter: filterFunction,
    });

    // recursively traverse folder tree then transform back to folder
    const getFolderNode: ListIterator<
      IFolderNode,
      Folder | ListOfRecursiveArraysOrValues<Folder>
    > = (folderNode) => {
      // find the folder object from folder list with the same id
      const folder = folders.find(({ id }) => id === folderNode.id)!;

      // if folder node has no children then return just the folder
      if (folderNode.children.length === 0) {
        return folder;
      }

      // if folder node has children return a list that has itself as a folder
      // and continuing the function into its children
      return [folder, flatMapDeep(folderNode.children, getFolderNode)];
    };

    // transform back into the original flat structure
    const deepFilteredFolders: Folder[] = flatMapDeep(
      folderTree,
      getFolderNode
    );

    return deepFilteredFolders;
  }

  openCreateFolderDialog(parentFolderId?: string) {
    const modalRef = this.modalService.open(CreateFolderDialogComponent, {
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.componentInstance.parentFolderId = parentFolderId;

    modalRef.result.then(
      (result: CreateFolderInput) => {
        lastValueFrom(this.createFolder(result));
      },
      () => {}
    );
  }

  openUpdateFolderDialog(
    folderId: string,
    currentFolderState: Pick<Folder, 'name' | 'isPublic'>
  ) {
    const modalRef = this.modalService.open(UpdateFolderDialogComponent, {
      size: 'sm',
      backdrop: 'static',
    });

    modalRef.componentInstance.currentName = currentFolderState.name;
    modalRef.componentInstance.currentIsPublic = currentFolderState.isPublic;

    modalRef.result.then(
      ({ name, isPublic }: IUpdateFolderForm) => {
        // if all fields has no change, don't send a request
        if (
          name === currentFolderState.name &&
          isPublic === currentFolderState.isPublic
        )
          return;

        // defaults to undefined if a field has no change
        lastValueFrom(
          this.updateFolder({
            id: folderId,
            name: name !== currentFolderState.name ? name : undefined,
            isPublic:
              isPublic !== currentFolderState.isPublic ? isPublic : undefined,
          })
        );
      },
      () => {}
    );
  }

  /**
   *
   * @param folders
   */
  openDeleteFolderDialog(folders: Pick<Folder, 'id' | 'name'>[]) {
    const folderNames = folders.map(({ name }) => name);
    const folderIds = folders.map(({ id }) => id);
    const modalRef = this.modalService.open(DeleteFolderDialogComponent);

    modalRef.componentInstance.folderNames = folderNames;

    modalRef.result.then(
      async () => {
        const profileId = this.currentUserService.currentProfile?.id;
        if (!profileId) return console.error('no profile id');

        const trashFolder = await this.getTrashFolder();

        // move the folder to the trash folder
        if (trashFolder) {
          await lastValueFrom(
            this.moveFolderListToFolder(folderIds, trashFolder.id)
          );
        }

        //TODO: possible optimization for this function
        // Currently based on the specs given, the trash folder should exist in
        // every profile and cannot be deleted, so in my opinion, there is no
        // need in making a fetch for the trash folder to the backend.
        // This means in moving things to the trash folder we only need the following:
        //  1) folder id of the target folder to be moved to the trash folder
        //  2) dedicated mutation to move things to the trash folder
        // so in this way we only do 1 backend call instead of 2
      },
      () => {}
    );
  }

  openPermanentDeleteFolderDialog(folders: Pick<Folder, 'id' | 'name'>[]) {
    const folderNames = folders.map(({ name }) => name);
    const folderIds = folders.map(({ id }) => id);
    const modalRef = this.modalService.open(DeleteFolderDialogComponent);

    modalRef.componentInstance.folderNames = folderNames;
    modalRef.componentInstance.permanentDeleteMode = true;

    modalRef.result.then(
      () => {
        lastValueFrom(this.deleteFolders(folderIds));
      },
      () => {}
    );
  }

  openMoveFolderToFolderDialog(folders: Pick<Folder, 'id' | 'name'>[]) {
    const folderIds = folders.map(({ id }) => id);
    const modal = this.modalService.open(MoveFolderToFolderDialogComponent, {
      backdrop: 'static',
    });

    modal.componentInstance.selectedFolders = folders;

    modal.result.then(
      (destinationFolderId: string | null) => {
        lastValueFrom(
          this.moveFolderListToFolder(folderIds, destinationFolderId)
        );
      },
      () => {}
    );
  }

  async refetchQueries() {
    // this activates when both queries does not exist
    if (!this.profileFoldersQuery && !this.profileFoldersWithMediasQuery) {
      this.apollo.client.reFetchObservableQueries();
      return;
    }

    // refetch only if the query exist
    // note: normally 1 of the
    this.profileFoldersQuery?.refetch();
    this.profileFoldersWithMediasQuery?.refetch();

    // TODO: we should replace reFetchObservableQueries() with refetchQueries() later
    // currently its not available in our current apollo client.
    // reFetchObservableQueries() refetches all active query observables
    // this makes it somewhat heavy, we only want specific queries to
    // be refetched
    // https://www.apollographql.com/docs/react/api/core/ApolloClient/#ApolloClient.reFetchObservableQueries
  }
}
