import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ConfirmDialogComponent } from './confirm-dialog.component';

@Component({
  standalone: true,
  imports: [TranslateModule, ConfirmDialogComponent],
  selector: 'delete-folder-dialog',
  template: `
    <app-confirm-dialog
      headerText="DELETE_FOLDER"
      [useDangerButton]="true"
      [closeButtonText]="'PROCEED'"
      [dismissButtonText]="'CANCEL'"
    >
      @if(permanentDeleteMode){
      <div class="card mb-3">
        <h4 class="card-header bg-danger mb-3">
          {{ 'DELETE_FOLDER_PROMPT' | translate }}
        </h4>
        <div class="card-body p-3">
          @for(name of folderNames; track name){
          <div class="mb-3">{{ name }}</div>
          }
        </div>
        <div class="badge bg-danger">
          <div class="p-2 text-white fw-bold fs-4">
            {{ 'PERMANENT_DELETE_FOLDER_WARNING' | translate }}
          </div>
          <div class="text-wrap">
            {{ 'PERMANENT_DELETE_FOLDER_PROMPT' | translate }}
          </div>
        </div>
      </div>
      } @else{
      <div class="card">
        <h4 class="card-header bg-warning mb-3">
          {{ 'FOLDER_TO_TRASH_PROMPT_HEADER' | translate }}
        </h4>
        <div class="card-body p-3">
          @for(name of folderNames; track name){
          <p>{{ name }}</p>
          }
        </div>
        <div class="badge bg-dark text-start mt-3">
          <div class="mb-2 text-white fw-bold fs-4">
            {{ 'TRASH_FOLDER_INFO_HEADER' | translate }}
          </div>
          <div class="text-wrap">
            {{ 'TRASH_FOLDER_INFO_PROMPT' | translate }} <br />
            {{ 'TRASH_FOLDER_INFO_PROMPT_1' | translate }}
            <hr />
            <span class="badge bg-warning">
              {{ 'TRASH_FOLDER_INFO_PROMPT_2' | translate }}
            </span>
          </div>
        </div>
      </div>
      }
    </app-confirm-dialog>
  `,
})
export class DeleteFolderDialogComponent {
  @Input() folderNames!: string[];
  @Input() permanentDeleteMode: boolean = false;
}
