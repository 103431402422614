import {
  signalStore,
  withState,
  withComputed,
  withMethods,
  patchState,
  withHooks,
} from '@ngrx/signals';
import { rxMethod } from '@ngrx/signals/rxjs-interop';
import { tapResponse } from '@ngrx/operators';
import {
  CurrentUserService,
  FolderService,
  MediaService,
} from '@desquare/services';
import { computed, inject } from '@angular/core';
import { distinctUntilChanged, pipe, switchMap } from 'rxjs';
import { GraphQLFormattedError } from 'graphql';
import {
  Folder,
  Media,
  MediaFiltersInput,
  MediaVisibilityType,
  ResourceType,
} from '@designage/gql';
import { withEntities } from '@ngrx/signals/entities';
import { cloneDeep } from 'lodash';
import { IMediaFilterButtonGroupState } from '@desquare/interfaces';

type IMediaSignal = {
  media: Media[];
  selectedFolderId: string | null;
  filters: MediaFiltersInput;
  mediaTypeFilter: IMediaFilterButtonGroupState;
  globalSearch: boolean;
  folderTree: Folder[];
  loading: boolean;
  errors: GraphQLFormattedError<Record<string, any>>[] | null;
};

const initialState: IMediaSignal = {
  media: [],
  selectedFolderId: null,
  filters: {
    mediaVisibility: [
      MediaVisibilityType.Default,
      MediaVisibilityType.Template,
      MediaVisibilityType.Playlist,
    ],
    resourceType: [ResourceType.Image, ResourceType.Video],
    name: '',
  },
  mediaTypeFilter: {
    image: false,
    video: false,
    templateImage: false,
    templateVideo: false,
    playlistImage: false,
    playlistVideo: false,
  },
  globalSearch: false,
  folderTree: [],
  loading: true,
  errors: null,
};

export const MediaStore = signalStore(
  { providedIn: 'root' },
  withState(initialState),
  withMethods(
    (
      store,
      mediaService = inject(MediaService),
      folderService = inject(FolderService),
      currentUserService = inject(CurrentUserService)
    ) => ({
      getMediaByProfileFromApi: rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return mediaService.getProfileMediasFromApi$().pipe(
              tapResponse({
                next: ({ media, loading, errors }) => {
                  patchState(store, {
                    media,
                    loading,
                    errors: errors ? [...errors] : [],
                  });
                },
                error: console.error,
              })
            );
          })
        )
      ),
      getFoldersFromApi: rxMethod<void>(
        pipe(
          distinctUntilChanged(),
          switchMap(() => {
            return folderService
              .getProfileFoldersObs(currentUserService.getCurrentProfileId())
              .pipe(
                tapResponse({
                  next: ({ data, loading, errors }) => {
                    patchState(store, {
                      folderTree: data.profile?.folders ?? [],
                      loading,
                      errors: errors ? [...errors] : [],
                    });
                  },
                  error: console.error,
                  finalize: () => patchState(store, { loading: false }),
                })
              );
          })
        )
      ),

      setFilter: (filters: MediaFiltersInput) => {
        patchState(store, {
          filters: { ...store.filters(), ...filters },
        });
      },

      setGlobalSearch: (globalSearch: boolean) => {
        patchState(store, {
          globalSearch,
        });
      },

      toggleMediaTypeFilter: (
        mediaType:
          | 'image'
          | 'video'
          | 'templateImage'
          | 'templateVideo'
          | 'playlistImage'
          | 'playlistVideo'
      ) => {
        const mediaTypeFilter = { ...store.mediaTypeFilter() };
        mediaTypeFilter[mediaType] = !mediaTypeFilter[mediaType];

        const noFilters = Object.values(mediaTypeFilter).every(
          (v) => v === false
        );

        const filterOutputList: {
          mediaVisibility: MediaVisibilityType[];
          resourceType: ResourceType[];
        } = noFilters
          ? {
              mediaVisibility: [
                MediaVisibilityType.Default,
                MediaVisibilityType.Template,
                MediaVisibilityType.Playlist,
              ],
              resourceType: [ResourceType.Image, ResourceType.Video],
            }
          : { mediaVisibility: [], resourceType: [] };

        if (mediaTypeFilter.image || mediaTypeFilter.video) {
          filterOutputList.mediaVisibility.push(MediaVisibilityType.Default);
        }
        if (mediaTypeFilter.image) {
          filterOutputList.resourceType.push(ResourceType.Image);
        }
        if (mediaTypeFilter.video) {
          filterOutputList.resourceType.push(ResourceType.Video);
        }
        if (mediaTypeFilter.templateImage || mediaTypeFilter.templateVideo) {
          filterOutputList.mediaVisibility.push(MediaVisibilityType.Template);
        }
        if (mediaTypeFilter.templateImage) {
          filterOutputList.resourceType.push(ResourceType.Image);
        }
        if (mediaTypeFilter.templateVideo) {
          filterOutputList.resourceType.push(ResourceType.Video);
        }
        if (mediaTypeFilter.playlistImage || mediaTypeFilter.playlistVideo) {
          filterOutputList.mediaVisibility.push(MediaVisibilityType.Playlist);
        }
        if (mediaTypeFilter.playlistImage) {
          filterOutputList.resourceType.push(ResourceType.Image);
        }
        if (mediaTypeFilter.playlistVideo) {
          filterOutputList.resourceType.push(ResourceType.Video);
        }
        patchState(store, {
          mediaTypeFilter,
          filters: { ...store.filters(), ...filterOutputList },
        });
      },

      setSelectedFolderId: (selectedFolderId: string | null) => {
        patchState(store, {
          selectedFolderId,
        });
      },
      getTotalItemsInFolder: (nodeId: string) => {
        const medias = store
          .media()
          .filter(({ folderId }) => folderId === nodeId);
        if (!medias) return 0;

        return medias.length;
      },
      getMediaById: (id: string) => {
        return store.media().find((media) => media.id === id);
      },
    })
  ),
  withHooks((store) => ({
    onInit() {
      // Load products when the store is initialized
      store.getMediaByProfileFromApi();
      store.getFoldersFromApi();
    },
  })),
  withComputed((store) => ({
    filteredMedia: computed((): Media[] => {
      let filteredMediaClone = cloneDeep(
        store
          .media()
          .filter(
            (media) =>
              store
                .filters()
                .mediaVisibility!.find((type) => media.visibility === type) &&
              store.filters().resourceType!.find((type) => media.type === type)
          )
      );

      if (store.filters().name === '') {
        return filteredMediaClone.filter(
          (media) => media.folderId === store.selectedFolderId()
        );
      } else {
        store.globalSearch()
          ? (filteredMediaClone = filteredMediaClone.map((media) => {
              const folderName =
                store
                  .folderTree()
                  .find((folder) => folder.id === media.folderId)?.name ??
                'Root';
              media.name = `${media.name} <br/> <span class="fw-light fs-6 lh-lg">folder: ${folderName} </span>`;
              return media;
            }))
          : (filteredMediaClone = filteredMediaClone.filter(
              (media) => media.folderId === store.selectedFolderId()
            ));

        return filteredMediaClone.filter((media) => {
          return media.name
            .toLocaleLowerCase()
            .includes(store.filters().name?.toLocaleLowerCase() as string);
        });
      }
    }),
  }))
);
