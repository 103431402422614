import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { IUpdateFolderForm } from '@desquare/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    FormDialogComponent,
    NgbTooltip,
  ],
  selector: 'update-folder-dialog',
  template: `<app-form-dialog
    headerText="UPDATE_FOLDER"
    [valid]="folderForm.valid"
    [pristine]="folderForm.pristine"
    [values]="folderForm.value"
    dismissButtonText="CANCEL"
    closeButtonText="Save"
  >
    <form [formGroup]="folderForm">
      <div class="mb-3">
        <label class="form-label" for="name">{{ 'NAME' | translate }}</label>
        <input
          type="text"
          class="form-control form-control-dark"
          formControlName="name"
          autocomplete="off"
        />
      </div>
      <div
        class="alert alert-danger"
        *ngIf="
          folderForm &&
          !folderForm.controls['name'].pristine &&
          folderForm.controls['name'].hasError('required')
        "
      >
        {{ 'NAME_IS_REQUIRED' | translate }}
      </div>
      <!-- <div
        class="mb-3 form-check"
        ngbTooltip="{{ 'IS_PUBLIC_FOLDER_TT' | translate }}"
      >
        <label class="form-check-label" for="isPublic"
          >{{ 'IS_PUBLIC_FOLDER' | translate }}?
        </label>
        <input
          class="form-check-input"
          type="checkbox"
          formControlName="isPublic"
        />
      </div> -->
    </form>
  </app-form-dialog> `,
  styles: [
    `
      .is-public-field {
        display: flex;
        align-items: center;
      }

      .is-public-field label {
        margin-bottom: 0;
      }
    `,
  ],
})
export class UpdateFolderDialogComponent implements OnInit {
  // current folder states
  @Input() currentName!: string;
  @Input() currentIsPublic!: string;

  // @Input() parentFolderId?: string

  get updateFolderInput(): IUpdateFolderForm {
    return this.folderForm.value;
  }

  folderForm!: FormGroup;
  get isFormValid() {
    return this.folderForm.valid;
  }
  get isFormPristine() {
    return this.folderForm.pristine;
  }

  constructor(private formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.initForm();
  }

  // TODO: logic to check if nothing was updated (currentName === submittedName)
  // then nothing should happen (close dialog)

  initForm() {
    this.folderForm = this.formBuilder.group({
      name: [this.currentName, Validators.required], // cannot be an empty string ('') or the same name
      isPublic: this.currentIsPublic,
      // parentFolderId: this.parentFolderId // moving folder into folder will be done through drag and drop
    });
  }
}
